// import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Button,
  createIcon,
  Flex,
  List,
  ListItem,
  ListItemProps,
  Text,
  useColorModeValue as mode,
  useColorModeValue
} from '@chakra-ui/react';
import * as React from 'react';

const CheckIcon = createIcon({
  viewBox: '0 0 17 12',
  d: 'M0 5.82857L1.64571 4.11429L5.48571 7.2L14.8114 0L16.4571 1.71429L5.48571 12L0 5.82857Z'
});

const PricingDetail = (props: ListItemProps) => {
  const { children, ...rest } = props;
  return (
    <ListItem display='flex' alignItems='flex-start' fontWeight='medium' {...rest}>
      <CheckIcon mr='4' mt='1' color={mode('brand.500', 'secondary.400')} />
      <Text as='span' display='inline-block'>
        {children}
      </Text>
    </ListItem>
  );
};

const PricingDetailNegative = (props: ListItemProps) => {
  const { children, ...rest } = props;
  return (
    <ListItem display='flex' alignItems='flex-start' fontWeight='medium' {...rest}>
      {/* <CloseIcon mr='4' mt='1' color={mode('red.500', 'red.300')} /> */}
      <Text as='span' display='inline-block'>
        {children}
      </Text>
    </ListItem>
  );
};

const PopularBadge = (props: BoxProps) => (
  <Box
    whiteSpace='nowrap'
    top='-4'
    left='50%'
    transform='translateX(-50%)'
    pos='absolute'
    rounded='md'
    fontWeight='bold'
    fontSize='sm'
    px='4'
    py='1'
    textTransform='uppercase'
    bg={useColorModeValue('secondary.500', 'secondary.500')}
    // color='brand.900'
    color={useColorModeValue('gray.100', 'gray.100')}
    {...props}
  />
);

interface PriceDisplayProps {
  currency: string;
  price?: number | string;
  asPerMonth?: boolean;
}

const PriceDisplay = (props: PriceDisplayProps) => {
  const { currency, price, asPerMonth } = props;
  return (
    <Flex w='100%' align='center' justify='center' my='5' fontWeight='extrabold'>
      <Text fontSize='4xl' mr='2'>
        {price && price !== 'Free' && price !== 'Custom' && currency}
      </Text>
      <Text fontSize='72px' lineHeight='1' letterSpacing='tight'>
        {price || 'Custom'}
      </Text>
      {asPerMonth && (
        <Text lineHeight='1' letterSpacing='tight' pl={2}>
          / month
        </Text>
      )}
    </Flex>
  );
};

const PricingWrapper = (props: BoxProps & { highlight?: boolean }) => {
  const { highlight, ...rest } = props;

  const popularStyles: BoxProps = {
    borderWidth: '2px',
    borderColor: mode('secondary.400', 'secondary.500')
  };

  const styles = highlight ? popularStyles : null;

  return (
    <Box
      _hover={{
        transform: 'scale(1.05)'
      }}
      transition='0.3s'
      w='full'
      mx='auto'
      // bg={mode('brand.100', 'brand.800')}
      px='10'
      pt='8'
      pb='8'
      rounded='lg'
      shadow='base'
      position='relative'
      {...styles}
      {...rest}
    />
  );
};

interface PricingCardProps extends BoxProps {
  features: string[];
  featuresNeg?: string[];
  popular?: boolean;
  currentPlan?: boolean;
  name: string;
  description: string;
  price: string | number;
  href?: string;
  hideButton?: boolean;
  asPerMonth?: boolean;
  buttonText: string;
}

export const PricingCard = (props: PricingCardProps) => {
  const {
    features,
    name,
    description,
    price,
    popular,
    currentPlan,
    featuresNeg,
    href,
    hideButton,
    buttonText,
    asPerMonth,
    ...rest
  } = props;
  return (
    <PricingWrapper highlight={popular} bg='bg-surface-landing' {...rest}>
      {popular && <PopularBadge>Recommended option </PopularBadge>}
      {currentPlan && <PopularBadge>Current plan</PopularBadge>}

      <Flex direction='column' justify='center'>
        <Text align='center' fontSize='2xl' fontWeight='bold'>
          {name}
        </Text>
        <Text align='center' mt='2' color={mode('gray.600', 'gray.400')} mx='auto'>
          {description}
        </Text>
        <PriceDisplay currency='$' price={price} asPerMonth={asPerMonth} />
      </Flex>

      <List stylePosition='outside' mt='8' spacing={4}>
        {features.map((feature, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <PricingDetail key={`${idx}-PricingDetail`}>{feature}</PricingDetail>
        ))}
      </List>
      <List stylePosition='outside' mt='4' spacing={4} mb='16'>
        {featuresNeg?.map((featureNeg, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <PricingDetailNegative key={`${idx}-PricingDetailNegative`}>{featureNeg}</PricingDetailNegative>
        ))}
      </List>

      <div
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bottom: 0,
          left: 0,
          width: '100%',
          marginBottom: '20px',
          marginTop: '20px'
        }}
      >
        {href ? (
          <Button
            as='a'
            // w='min-intrinsic'
            href={href}
            w='80%'
            minH='3.5rem'
            rounded='lg'
            fontWeight='bold'
            colorScheme='brand'
            bg={popular ? mode('brand.500', 'brand.400') : mode('brand.400', 'brand.500')}
            // variant={popular ? 'solid' : 'outline'}
            isDisabled={currentPlan}
            display={hideButton ? 'none' : 'flex'}
            cursor='pointer'
          >
            {currentPlan ? 'Current plan' : buttonText}
          </Button>
        ) : (
          <Button
            // w='min-intrinsic'
            w='80%'
            minH='3.5rem'
            rounded='lg'
            fontWeight='bold'
            colorScheme='brand'
            bg={popular ? mode('brand.500', 'brand.400') : mode('brand.400', 'brand.500')}
            // variant={popular ? 'solid' : 'outline'}
            isDisabled={currentPlan}
            display={hideButton ? 'none' : 'flex'}
          >
            {currentPlan ? 'Current plan' : buttonText}
          </Button>
        )}
      </div>
    </PricingWrapper>
  );
};
