import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { random, uniqBy } from 'lodash';
import { keyframes } from '@chakra-ui/react';

dayjs.extend(relativeTime);
dayjs.extend(duration);

export const phoneRegExp = /^((\+[1-9]{2})|0)[1-8][0-9]{8}$/;
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_.])[\w\d!@#$%^&*\-_.]/;
export const nameRegExp = /^[^±!@£$%^&*_+§¡€#¢¶•ªº«\\/<>?:;|=.,()]*$/;
export const orgNameRegExp = /^[^±!@£$%^&*_+§¡€#¢¶•ªº«\\/<>?:;|=.,()]*$/;
export const tempEmail = /^[a-zA-Z0-9-+.]+(@simplyanvil\.com|@stellr-net\.com|@goingsolo\.com)$/;
export const emailForm = /^[a-zA-Z0-9-+.]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const sanitizePhone = (phone: string): string => phone.replace(/^0/, '+27');

export const calculateProcessingTime = (started: string, ended: string): string => {
  return dayjs(started).from(dayjs(ended), true);
};

export const calculateProcessingUnits = (started: string, ended: string): number => {
  const overheadModifier = 1.15;

  const durations = dayjs.duration(dayjs(started).diff(dayjs(ended))).asSeconds();

  return Math.ceil(durations * overheadModifier);
};

export const getFloatAnimation = () => {
  const startDegree: number = random(-1, 1);
  const endDegree: number = random(-1, 1);
  const time: number = random(6, 9);
  const keyFrames = keyframes`
      0% {
          transform: rotate(${startDegree}deg);
      translate(0, 0 px);
      }
      50% {
      rotate(${endDegree}deg);
          transform: translate(0, 7px);
      }
      100% {
          transform: rotate(${startDegree}deg);
      translate(0, - 0 px);
      }
  `;

  return `${keyFrames} infinite ${time}s ease-in-out`;
};

export const csvHeadersToAutocomplete = (parsedCsv: {
  [key: string]: string;
}): { label: string; type: string; info: string }[] => {
  // Support for objects and arrays
  const dottedKeys = Object.keys(parsedCsv)
    .filter((key) => key.indexOf('.') !== -1)
    .map((key) => {
      const keys = key.split('.');

      // Assume arrays
      if (parseInt(keys[1], 10) > 0) {
        return { label: `$${keys[0]}`, type: 'variable', info: `e.g. [ "${parsedCsv[key]}", ... ]` };
      }

      // Assume objects
      return { label: `$${keys[0]}`, type: 'variable', info: `e.g. { "${keys[1]}": ${parsedCsv[key]}, ... }` };
    });

  const dedupeDottedKeys = uniqBy(dottedKeys, (item) => item.label);

  const items = Object.keys(parsedCsv).map((key) => {
    return { label: `$${key}`, type: 'variable', info: `e.g. "${parsedCsv[key]}"` };
  });

  return [...dedupeDottedKeys, ...items];
};
