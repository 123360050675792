import React, { useState } from 'react';
import { Box, Heading, SimpleGrid, Text, useColorModeValue as mode } from '@chakra-ui/react';
import { CalcFields, CalculatorValues } from './calcFields';
import { calculatePlan } from '../../libs/calculate';

const Calculator = ({ callback }) => {
  const [plan, setPlan] = useState<any>();

  const calculatePlans = (values: CalculatorValues) => {
    const suggestedPlan = calculatePlan(values);
    setPlan(suggestedPlan);
    callback(suggestedPlan?.name);
  };

  return (
    <Box>
      <Box px={{ base: '4', md: '8' }}>
        <Heading as='h2' size={{ base: 'md', md: 'xl' }} fontWeight='extrabold' textAlign={{ sm: 'center' }}>
          Calculate your Smart Parse needs
        </Heading>
        <Text mt='4' mx='auto' fontSize='m' color={mode('brand.600', 'brand.400')} textAlign={{ sm: 'center' }}>
          Estimate your monthly requirements and reference it to one of our packages below.
        </Text>

        <SimpleGrid
          alignItems='flex-start'
          columns={{ base: 1, lg: 1 }}
          spacing={{ base: '12', lg: '8' }}
          px={{ base: 0, md: 6 }}
          pt={6}
        >
          <CalcFields callback={calculatePlans} />
        </SimpleGrid>
      </Box>

      {plan?.time && (
        <Box mt={6}>
          <Text as='h1' fontSize='xl' color={mode('brand.900', 'white')} pb={2} textAlign={{ sm: 'center' }}>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            We estimate a requirement of {new Intl.NumberFormat().format(Math.ceil(plan.time))} processing units.
          </Text>
          <Text as='h4' fontSize='sm' color={mode('brand.600', 'brand.400')} pb={4} textAlign={{ sm: 'center' }}>
            A processing unit is a simplified indication of the time and resources required to process a file. Larger
            files and slower destinations require more units to process.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default Calculator;
