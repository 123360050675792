import React, { FC, useEffect, useRef } from 'react';
import { Box, Heading, Text, useColorModeValue, useToken } from '@chakra-ui/react';
import { motion, useInView } from 'framer-motion';

interface FeatureTitleProps {
  id: string;
  title: string;
  desc: string;
  active: boolean;
  onInView: (index: string) => void;
}

const FeatureTitle: FC<FeatureTitleProps> = ({ id, title, desc, active, onInView }) => {
  const [colorDefault, colorHighlight] = useToken('colors', [
    useColorModeValue('blackAlpha.600', 'whiteAlpha.600'), // Default
    useColorModeValue('gray.900', 'gray.50') // Highlight
  ]);
  const ref = useRef<HTMLHeadingElement>(null);
  const isInView = useInView(ref, { margin: '-40% 0px -50% 0px' });

  useEffect(() => {
    if (isInView) {
      onInView(id);
    }
  }, [isInView]);

  return (
    <Box as={motion.div} py={20} animate={isInView || active ? { x: [0, 10] } : 'inherit'}>
      <Heading
        as={motion.h4}
        ref={ref}
        size='sm'
        animate={isInView || active ? { color: [colorDefault, colorHighlight] } : 'inherit'}
        color={isInView || active ? 'gray.900' : 'gray.300'}
        fontWeight='extrabold'
        mb={4}
      >
        {title}
      </Heading>
      <Text my={2} fontSize='lg' opacity={0.8}>
        {desc}
      </Text>
    </Box>
  );
};

export default FeatureTitle;
