import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { NextSeo, OrganizationJsonLd } from 'next-seo';
import { MdCheckCircle } from 'react-icons/md';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { OrganisationTier } from '@simplyanvil/smartparse-types';
import { FaQuoteLeft, FaQuoteRight, FaStar } from 'react-icons/fa6';
import { Navbar } from '../components/chakraUIPro/Navbar';
import { HeroCard } from '../components/chakraUIPro/HeroCard';
import { ProductCards } from '../components/chakraUIPro/ProductCards';
import Calculator from '../components/calculator/Calculator';
import { Footer } from '../components/chakraUIPro/Footer';
import { QuickFeatures } from '../components/LandingPage/QuickFeatures';
import { ExploreFeatures } from '../components/LandingPage/ExploreFeatures';
import { getFloatAnimation } from '../libs/utils';
import FAQs from '../components/LandingPage/FAQs';
import Features from '../components/LandingPage/Features';
import Banner from '../components/LandingPage/Banner';
import CaseStudies from '../components/LandingPage/CaseStudies';
import { MappingTools } from '../components/MappingTools';

const WavesRough = dynamic(() => import('../components/WavesLarge'), {
  // loading: () => <p>Loading Ocean Particles...</p>,
  ssr: false
});

const Waves = dynamic(() => import('../components/Waves'), {
  // loading: () => <p>Loading Ocean Particles...</p>,
  ssr: false
});

const OceanParticles = dynamic(() => import('../components/OceanParticles'), {
  ssr: false
});

function Index() {
  const [recommendedPlan, setRecommendedPlan] = useState(OrganisationTier.BASIC);

  const calculatorCall = (popular) => {
    setRecommendedPlan(popular);
  };

  return (
    <>
      <NextSeo
        title='Smart Parse | From CSV to API in No Time'
        description='Ditch the downtime and reduce implementation costs with near-zero developer overhead. Free up your resources to build what matters.'
        canonical='https://smartparse.io/'
      />
      <OrganizationJsonLd
        name='SmartParse'
        legalName='Simply Anvil (Pty) Ltd'
        url='https://smartparse.io/'
        logo='https://smartparse.io/images/logo-share.png'
        sameAs={['https://smartparse.io']}
        contactPoint={[
          {
            contactType: 'info',
            email: 'info@simplyanvil.com',
            availableLanguage: ['English', 'Afrikaans']
          }
        ]}
      />

      {/* Announcement banner */}
      <Banner zIndex={1} />

      <Box
        bgGradient={useColorModeValue('linear(to-b, gray.200, gray.400)', 'linear(to-b, gray.600, gray.800)')}
        position='relative'
        pb={{ base: '50px', md: '140px' }}
      >
        <WavesRough color={useColorModeValue('brand.300', 'brand.500')} zIndex={0} />

        <Box maxW='8xl' mx='auto' p={{ base: 4, md: 8 }} textAlign='left' zIndex={1}>
          <Navbar />
          <HeroCard />
        </Box>
      </Box>

      <Box bgGradient={useColorModeValue('linear(to-b, brand.300, brand.500)', 'linear(to-b, brand.500, brand.700)')}>
        <Box position='relative' pb='250px'>
          <Waves color={useColorModeValue('brand.600', 'brand.800')} />

          <VStack spacing={{ base: 12, md: 20 }} maxW='8xl' mx='auto' p={8} textAlign='left'>
            <Box id='features'>
              <QuickFeatures />
            </Box>

            <VStack
              spacing={4}
              mt={40}
              mb={{ base: 40, md: 10 }}
              maxW={{ base: 'full', md: '4xl' }}
              mx='auto'
              alignItems='right'
            >
              <HStack spacing={2} mx='auto'>
                <Icon
                  as={FaStar}
                  color='secondary.500'
                  _dark={{ color: 'secondary.400' }}
                  boxSize={{ base: 12, md: 20 }}
                />
                <Icon
                  as={FaStar}
                  color='secondary.500'
                  _dark={{ color: 'secondary.400' }}
                  boxSize={{ base: 12, md: 20 }}
                />
                <Icon
                  as={FaStar}
                  color='secondary.500'
                  _dark={{ color: 'secondary.400' }}
                  boxSize={{ base: 12, md: 20 }}
                />
                <Icon
                  as={FaStar}
                  color='secondary.500'
                  _dark={{ color: 'secondary.400' }}
                  boxSize={{ base: 12, md: 20 }}
                />
                <Icon
                  as={FaStar}
                  color='secondary.500'
                  _dark={{ color: 'secondary.400' }}
                  boxSize={{ base: 12, md: 20 }}
                />
              </HStack>
              <Text textAlign='center' fontSize={{ base: 'xl', md: '4xl' }}>
                <Icon as={FaQuoteLeft} mr={2} color='brand.700' _dark={{ color: 'brand.300' }} /> &hellip; a 120x
                increase in processing speed (from 2 hours to minutes) for big orders is truly astounding.
                <Icon as={FaQuoteRight} ml={2} color='brand.700' _dark={{ color: 'brand.300' }} />
              </Text>
              <Text textAlign='right' color='brand.800' _dark={{ color: 'brand.100' }} fontSize='xl'>
                Hannes Bodenstein (Global Implementations at Stellr)
              </Text>
            </VStack>

            <Box id='how-it-works'>
              <Features />
            </Box>

            <Box id='mapping-editor'>
              <MappingTools />
            </Box>

            <Box id='case-studies' my={{ base: 4, md: 12 }}>
              <CaseStudies />
            </Box>

            <ExploreFeatures />

            {/* Security */}
            <Box id='security'>
              <Heading as='h2' size='3xl' mb={2} fontWeight='extrabold'>
                Superior Data Security with SmartParse
              </Heading>
              <Heading as='h3' size='sm' mb={6} color={useColorModeValue('gray.600', 'gray.400')}>
                Utilising Top-Tier Encryption Protocols and Standards
              </Heading>
              <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }} gap={10}>
                <GridItem colSpan={3}>
                  <Text mb={6} fontSize='xl'>
                    SmartParse ensures the safety of your data with our SFTP as a Service for secure in-transit
                    encryption, leveraging SSH protocol standards. At rest, your data is encrypted using
                    industry-leading AES-256 standards, providing robust protection akin to that used by global
                    financial institutions. Our key management practices are fortified by FIPS 140-2 validated hardware
                    security modules, ensuring the utmost security for your encryption keys and maintaining the
                    integrity and confidentiality of your data at every stage.
                  </Text>
                  <Button
                    as={NextLink}
                    href='/sign-up/'
                    mt='8'
                    w={{ base: '100%', md: 'inherit' }}
                    minW='14rem'
                    colorScheme='teal'
                    size='lg'
                    height='14'
                    px='8'
                    fontSize='md'
                    fontWeight='bold'
                    bgColor='brand.400'
                  >
                    Try it out now
                  </Button>
                </GridItem>
                <GridItem colSpan={2}>
                  <Box
                    px={{ base: '4', md: '6' }}
                    py={{ base: '5', md: '6' }}
                    bg='bg-surface-landing'
                    borderRadius='lg'
                    animation={getFloatAnimation()}
                  >
                    <List spacing={3} fontSize='lg'>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='secondary.500' />
                        Encryption in transit with SSL and SFTP
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='secondary.500' />
                        Encryption at rest with AES-256
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='secondary.500' />
                        FIPS 140-2 validated hardware security modules
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='secondary.500' />
                        Custom data retention policies (Coming soon)
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='secondary.500' />
                        Selective field masking (Coming soon)
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='secondary.500' />
                        Bring your own keys (Coming soon)
                      </ListItem>
                    </List>
                  </Box>
                </GridItem>
              </Grid>
              <OceanParticles id='tsParticles4' size={800} />
            </Box>

            {/* Calculator */}
            <Box px={4} py={8} id='calculator' bg='bg-surface-landing' borderRadius='3xl' maxW='2xl' mt={12} mx='auto'>
              <Calculator callback={calculatorCall} />
              <OceanParticles id='tsParticles5' size={600} />
            </Box>

            {/* Pricing */}
            <Box id='pricing'>
              <ProductCards popular={recommendedPlan} />
            </Box>

            {/* Frequently asked questions */}
            <Box>
              <OceanParticles id='tsParticles6' size={450} />
              <FAQs />
            </Box>

            {/* <CallToAction /> */}
          </VStack>
        </Box>

        {/* Footer */}
        <Box bgGradient={useColorModeValue('linear(to-b, brand.600, gray.700)', 'linear(to-b, brand.800, brand.700)')}>
          <Box maxW='8xl' mx='auto' p={8} textAlign='left'>
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Index;
