import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';

import { Field, Form, Formik } from 'formik';

export interface CalculatorValues {
  lines: number;
  apiRes: number;
  files: number;
  concurrency: number;
}

export function CalcFields({ callback }) {
  const onSubmit = (values) => {
    callback(values);
  };

  return (
    <Box>
      <Formik initialValues={{ apiRes: 350, lines: 100, files: 10, concurrency: 10 }} onSubmit={onSubmit}>
        {({ values, handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Flex flexDirection={{ base: 'column', sm: 'row', md: 'row', lg: 'row' }} mx='auto'>
              <Field name='files'>
                {({ field, form }) => (
                  <FormControl mb='8' isInvalid={form.errors.password && form.touched.password} alignItems='center'>
                    <FormLabel as='h3' fontSize='xl'>
                      Files per month
                    </FormLabel>

                    <Flex>
                      <NumberInput
                        mt={2}
                        mr='2rem'
                        mb={2}
                        id='files'
                        defaultValue={0}
                        min={1}
                        {...field}
                        onChange={(val) => form.setFieldValue(field.name, val)}
                        borderRadius='lg'
                        borderColor='brand.300'
                        bg={mode('white', 'gray.700')}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper
                            bg={mode('brand.100', 'brand.600')}
                            color={mode('brand.600', 'brand.100')}
                          />
                          <NumberDecrementStepper
                            bg={mode('brand.100', 'brand.600')}
                            color={mode('brand.600', 'brand.100')}
                          />
                        </NumberInputStepper>
                      </NumberInput>
                    </Flex>
                    <Text as='h2' fontSize='sm' color={mode('brand.600', 'brand.400')} mr='10'>
                      Estimate number of files that will need to be processed in a month
                    </Text>
                    {/* @ts-ignore TODO: Fix */}
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='lines'>
                {({ field, form }) => (
                  <FormControl mb='8' isInvalid={form.errors.lines && form.touched.lines}>
                    <FormLabel fontSize='xl'>Lines</FormLabel>

                    <Stack direction={{ base: 'column', sm: 'row', md: 'row', lg: 'row' }} mt={2}>
                      <Flex>
                        <NumberInput
                          mt={2}
                          mr='2rem'
                          mb={2}
                          defaultValue={0}
                          step={5}
                          id='lines'
                          min={1}
                          {...field}
                          onChange={(val) => form.setFieldValue(field.name, val)}
                          borderRadius='lg'
                          borderColor='brand.300'
                          bg={mode('white', 'gray.700')}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper
                              bg={mode('brand.100', 'brand.600')}
                              color={mode('brand.600', 'brand.100')}
                            />
                            <NumberDecrementStepper
                              bg={mode('brand.100', 'brand.600')}
                              color={mode('brand.600', 'brand.100')}
                            />
                          </NumberInputStepper>
                        </NumberInput>
                      </Flex>
                    </Stack>
                    <Text as='h2' fontSize='sm' color={mode('brand.600', 'brand.400')}>
                      Estimate lines per file
                    </Text>
                    {/* @ts-ignore TODO: Fix */}
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
            {/*            <Text as='h3' fontSize='md' color={mode('brand.600', 'brand.400')}>
              Technical considerations.
            </Text>
            <Text as='h4' fontSize='sm' color={mode('brand.600', 'brand.400')} pb={4}>
              If you don&apos;t know, use the defaults. Most systems can handle them.
            </Text> */}
            <Flex flexDirection={{ base: 'column', sm: 'row', md: 'row', lg: 'row' }}>
              <Field name='apiRes'>
                {({ field, form }) => (
                  <FormControl mb='8' isInvalid={form.errors.password && form.touched.password}>
                    <FormLabel fontSize='xl'>API Response (ms)</FormLabel>

                    <Flex>
                      <NumberInput
                        mb={2}
                        id='apiRes'
                        mr='2rem'
                        mt={2}
                        min={1}
                        value={values.apiRes}
                        {...field}
                        onChange={(val) => form.setFieldValue(field.name, val)}
                        borderRadius='lg'
                        borderColor='brand.300'
                        bg={mode('white', 'gray.700')}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper
                            bg={mode('brand.100', 'brand.600')}
                            color={mode('brand.600', 'brand.100')}
                          />
                          <NumberDecrementStepper
                            bg={mode('brand.100', 'brand.600')}
                            color={mode('brand.600', 'brand.100')}
                          />
                        </NumberInputStepper>
                      </NumberInput>
                    </Flex>
                    <Text as='h2' fontSize='sm' color={mode('brand.600', 'brand.400')}>
                      Estimate time for API to respond in milliseconds
                    </Text>
                    {/* @ts-ignore TODO: Fix */}
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name='concurrency'>
                {({ field, form }) => (
                  <FormControl mb='8' isInvalid={form.errors.password && form.touched.password}>
                    <FormLabel fontSize='xl'>Concurrency</FormLabel>
                    <Stack direction={{ base: 'column', sm: 'row', md: 'row', lg: 'row' }} mt={2}>
                      <Flex>
                        <NumberInput
                          mt={2}
                          mb={2}
                          mr='2rem'
                          min={1}
                          id='concurrency'
                          {...field}
                          onChange={(val) => form.setFieldValue(field.name, val)}
                          borderRadius='lg'
                          borderColor={mode('white', 'brand.300')}
                          bg={mode('white', 'gray.700')}
                        >
                          <NumberInputField step='0.05' />
                          <NumberInputStepper>
                            <NumberIncrementStepper
                              bg={mode('brand.100', 'brand.600')}
                              color={mode('brand.600', 'brand.100')}
                            />
                            <NumberDecrementStepper
                              bg={mode('brand.100', 'brand.600')}
                              color={mode('brand.600', 'brand.100')}
                            />
                          </NumberInputStepper>
                        </NumberInput>
                      </Flex>
                    </Stack>
                    <Text as='h2' fontSize='sm' color={mode('brand.600', 'brand.400')}>
                      Perform tasks simultaneously
                    </Text>
                    {/* @ts-ignore TODO: Fix */}
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex mb='8'>
              <Spacer />
            </Flex>

            <Center>
              <Button
                width='100%'
                type='submit'
                size='lg'
                bg={mode('brand.600', 'secondary.500')}
                color={mode('brand.100', 'gray.100')}
                onSubmit={callback}
              >
                Calculate your requirements
              </Button>
            </Center>
            {/* <pre>{JSON.stringify(values)}</pre> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}
