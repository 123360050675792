type calcHrsType = {
  files: number;
  concurrency: number;
  lines: number;
  apiRes: number;
};
export const calculateProcessingUnits = ({ files, concurrency, lines, apiRes }: calcHrsType): number => {
  const OVERHEAD = 1.15; // 15% TODO: Finalise
  // const ms: number = (apiRes * files * lines * OVERHEAD) / concurrency;
  const microUnits: number = ((apiRes * lines) / concurrency) * files * OVERHEAD;

  return Math.round((microUnits / 1000) * 100) / 100;
};

export const calculatePlan = ({ files, concurrency, lines, apiRes }: calcHrsType) => {
  const processingUnits = calculateProcessingUnits({ files, concurrency, lines, apiRes });

  if (processingUnits > 400000) {
    return {
      feature: ['Full client support ', 'Integrate with multiple APIs ', 'multiple users  '],
      name: 'Enterprise',
      price: '---',
      time: processingUnits
    };
  }
  if (processingUnits > 120000) {
    return {
      feature: ['Built in fault detection', 'Field alignment and mapping', 'full range of Audit trials'],
      name: 'Pro',
      price: '499',
      time: processingUnits
    };
  }
  if (processingUnits > 8000) {
    return {
      feature: ['Fault detection', 'Throttle capabilities ', 'limit throughput '],
      name: 'Base',
      price: '200',
      time: processingUnits
    };
  }
  if (processingUnits < 8000) {
    return {
      feature: ['Fault detection', 'Throttle capabilities ', 'limit throughput '],
      name: 'Starter',
      price: '200',
      time: processingUnits
    };
  }
  if (processingUnits > 300) {
    return {
      feature: ['Import flat-files ', 'Send data to your API', '3hours of processing free processing available'],
      name: 'Starter',
      price: 'STARTER',
      time: processingUnits
    };
  }
  return {
    feature: ['Import flat-files ', 'Send data to your API', '3hours of processing free processing available'],
    name: 'Free',
    price: 'FREE',
    time: processingUnits
  };
};
