/* eslint-disable max-len */
import { Box, Flex, Heading, Image, List, ListItem, Stack, Text } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import FeatureTitle from './FeatureTitle';
import FeatureCard from './FeatureCard';

interface ScrollingCardsProps {}

const features = [
  {
    id: 'feature-destinations',
    title: 'Intuitive Error Handling',
    desc: 'SmartParse simplifies troubleshooting with its intuitive error handling. Quickly identify and resolve data issues, ensuring a smooth and reliable migration process.',
    image: '/images/features/destinations.png',
    gradient: {
      direction: 'to-tr',
      from: 'transparent',
      to: 'transparent'
    }
  },
  {
    id: 'feature-api',
    title: 'API first',
    desc: "SmartParse uses an 'API-first' approach, offering seamless system integration for efficient data processing. It's the flexibility you need to power complex migrations.",
    image: '/images/features/api.png',
    gradient: {
      direction: 'to-br',
      from: 'transparent',
      to: 'transparent'
    }
  },
  {
    id: 'feature-map',
    title: 'Map your data',
    desc: "SmartParse's easy mapping simplifies data organisation, ensuring accuracy and consistency. Streamline complex data structures effortlessly.",
    image: '/images/features/map.png',
    gradient: {
      direction: 'to-bl',
      from: 'transparent',
      to: 'transparent'
    }
  },
  {
    id: 'feature-sftp',
    title: 'Upload your CSV or connect via SFTP',
    desc: 'Upload CSVs or connect via SFTP smoothly with SmartParse. Our platform supports various formats, ensuring secure and efficient data transfer.',
    image: '/images/features/sftp.png',
    gradient: {
      direction: 'to-tl',
      from: 'transparent',
      to: 'transparent'
    }
  },
  {
    id: 'feature-reporting',
    title: 'Comprehensive Reporting',
    desc: 'SmartParse offers detailed analytics and real-time updates with its comprehensive reporting tools. Make informed decisions and optimise your data strategies.',
    image: '/images/features/reporting.png',
    gradient: {
      direction: 'to-tr',
      from: 'transparent',
      to: 'transparent'
    }
  }
];
const Features: FC<ScrollingCardsProps> = () => {
  const [activeFeature, setActiveFeature] = useState<string>(features[0].id);

  const handleActiveFeature = (id: string) => {
    setActiveFeature(id);
  };

  return (
    <Flex w='full' gap={20} maxW='6xl' mx='auto' direction={{ base: 'column', md: 'row' }} position='relative'>
      <Box w='full' py={{ base: 0, md: '50vh' }} mt={{ base: 0, md: -10 }}>
        <Stack spacing={{ base: '4', md: '5' }} maxW='3xl'>
          <Stack spacing='3'>
            <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight='semibold' color='brand.100'>
              Developer friendly features
            </Text>
            <Heading size={{ base: 'sm', md: 'md' }}>Explore SmartParse</Heading>
          </Stack>
          <Text color='fg.muted' fontSize={{ base: 'lg', md: 'xl' }}>
            {/* Get started in seconds, configure to fit your exact needs. */}
          </Text>
        </Stack>

        <List>
          {features.map((feature) => (
            <ListItem key={feature.id}>
              <FeatureTitle
                id={feature.id}
                active={feature.id === activeFeature}
                onInView={handleActiveFeature}
                title={feature.title}
                desc={feature.desc}
              />
              <Box display={{ base: 'block', md: 'none' }} aspectRatio='1/1' w='full' position='relative'>
                <FeatureCard gradient={feature.gradient} active>
                  <Image
                    src={feature.image}
                    alt={feature.title}
                    width={{ base: '100%', lg: '600px' }}
                    height={{ base: 'auto' }}
                    loading='lazy'
                    objectFit='cover'
                  />
                </FeatureCard>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>

      <Flex
        position='sticky'
        top={{ base: '100%', md: 0 }}
        w='full'
        h='100vh'
        alignItems='center'
        display={{ base: 'none', md: 'flex' }}
      >
        <Box aspectRatio='1/1' w='full' position='relative'>
          {features.map((feature) => (
            <FeatureCard key={feature.id} gradient={feature.gradient} active={feature.id === activeFeature}>
              <Image
                src={feature.image}
                alt={feature.title}
                width={{ base: '100%', lg: '600px' }}
                height={{ base: 'auto' }}
              />
            </FeatureCard>
          ))}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Features;
