import { Box, Heading, Link, SimpleGrid, Text, useColorModeValue as mode, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { OrganisationTier } from '@simplyanvil/smartparse-types';
import { PricingCard } from './PricingCard';
import { AuthStatus, useAuthState } from '../../contexts/AuthContext';

/* TODO: handle free and enterprise */
const Plans = {
  FREE: '',
  STARTER: process.env.NEXT_PUBLIC_PLAN_STARTER,
  BASIC: process.env.NEXT_PUBLIC_PLAN_BASIC,
  PRO: process.env.NEXT_PUBLIC_PLAN_PRO,
  ENTERPRISE: 'enterprise id'
};

const cronpayPublicKey = process.env.NEXT_PUBLIC_CRONPAY_KEY;
const cronpayUrl = process.env.NEXT_PUBLIC_CRONPAY_URL;
const redirectUrlBase = process.env.NEXT_PUBLIC_URL;
const coreFeatures = ['Data quarantining', 'Circuit breakers', 'Notifications'];
const paidFeatures = ['SFTP uploads/downloads'];

export const ProductCards = ({
  popular,
  currentPlan,
  // signingUp = false,
  hideButton = false,
  buttonText = 'Get Started'
}: {
  popular: any;
  currentPlan?: any;
  // signingUp?: boolean;
  hideButton?: boolean;
  buttonText?: string;
}) => {
  const { currentOrganisation, sessionStatus, user } = useAuthState();
  const [state, setState] = useState(OrganisationTier.BASIC);
  useEffect(() => {
    setState(popular);
  }, [popular]);

  const buildRedirectUrl = ({ isSuccess }: { isSuccess: boolean }) => {
    return `${redirectUrlBase}/dashboard/${currentOrganisation?.id || 'no-id'}/payment?isSuccess=${
      isSuccess ? '1' : '0'
    }`;
  };

  const buildHref = ({ planId }: { planId: string }): string => {
    // If not signed in, navigate to sign-up with indication to pay
    if (sessionStatus !== AuthStatus.AUTHENTICATED || !currentOrganisation) {
      return `/sign-up/?plan=${planId}`;
    }
    // If signed in, navigate CronPay
    const params = {
      planId,
      token: cronpayPublicKey,
      fName: currentOrganisation?.billing?.name || user.fName,
      email: currentOrganisation?.billing?.email || user.email,
      ref: currentOrganisation?.id || 'no-id',
      successUrl: buildRedirectUrl({ isSuccess: true }),
      cancelUrl: buildRedirectUrl({ isSuccess: false }),
      metadata: JSON.stringify({ orgId: currentOrganisation.id, taxNumber: currentOrganisation?.billing?.taxNumber })
    };

    return `${cronpayUrl}?${new URLSearchParams(params).toString()}`;
  };

  return (
    <Box as='section' py='20' color={mode('brand.900', 'brand.100')}>
      <Box mx='auto' px={{ base: '0', md: '8' }}>
        <Heading as='h2' size={{ base: 'lg', md: '2xl' }} fontWeight='extrabold' textAlign={{ sm: 'center' }}>
          Pricing Plans
        </Heading>
        <Text mt='4' mx='auto' fontSize='xl' color={mode('gray.600', 'gray.400')} textAlign={{ sm: 'center' }}>
          Start building for free, and upgrade as you need. Our paid plans unlock additional features.
        </Text>
        <SimpleGrid
          minHeight='-moz-max-content'
          alignItems='flex-start'
          mt={{ base: '10', lg: '24' }}
          columns={{ base: 1, md: 2, lg: 4 }}
          spacing={{ base: '12', lg: '8' }}
        >
          <PricingCard
            height='full'
            popular={state === OrganisationTier.FREE}
            currentPlan={currentPlan === OrganisationTier.FREE}
            name='Intro'
            description='Up to 300 processing units available'
            price='Free'
            features={['Suitable for testing and small uploads', 'Browser uploads only', ...coreFeatures]}
            href='/sign-up/'
            hideButton={hideButton}
            buttonText={buttonText}
          />
          <PricingCard
            height='full'
            popular={state === OrganisationTier.STARTER}
            currentPlan={currentPlan === OrganisationTier.STARTER}
            name='Starter'
            description='Up to 8000 processing units available'
            price={119}
            features={['Suitable for infrequent or small uploads', ...coreFeatures, ...paidFeatures]}
            href={buildHref({ planId: Plans.STARTER })}
            hideButton={hideButton}
            asPerMonth
            buttonText={buttonText}
          />
          <PricingCard
            height='full'
            popular={state === OrganisationTier.BASIC}
            currentPlan={currentPlan === OrganisationTier.BASIC}
            name='Basic'
            description='Up to 120 000 processing units available'
            price={299}
            features={['Suitable for weekly uploads', ...coreFeatures, ...paidFeatures, 'Custom Throttling']}
            href={buildHref({ planId: Plans.BASIC })}
            hideButton={hideButton}
            buttonText={buttonText}
            asPerMonth
          />
          <PricingCard
            height='full'
            popular={state === OrganisationTier.PRO}
            currentPlan={currentPlan === OrganisationTier.PRO}
            name='Pro'
            description='Up to 400 000 processing units available'
            price={499}
            features={[
              'Suitable for daily uploads and large files ',
              ...coreFeatures,
              ...paidFeatures,
              'Custom Throttling'
            ]}
            href={buildHref({ planId: Plans.PRO })}
            hideButton={hideButton}
            buttonText={buttonText}
            asPerMonth
          />
          {/* <PricingCard
            popular={state === 'Enterprise' ? popular : null}
            currentPlan={currentPlan === 'enterprise'}
            name='Enterprise'
            description='Over 400 000 processing units available'
            price='Custom'
            features={[
              'Send data to your API',
              'Built in fault detection',
              'Field alignment and mapping',
              'Full range of Audit trials',
              'Suitable for any upload frequency'
            ]}
            hideButton={hideButton}
            buttonText='Contact us'
            href='mailto:info@simplyanvil.com'
          /> */}
        </SimpleGrid>
      </Box>
      <VStack spacing={2} mt={20}>
        <Heading as='h4' size='xs' fontSize='3xl'>
          Need even more?
        </Heading>
        <Text>
          Please{' '}
          <Link as='a' href='mailto:info@simplyanvil.com' target='_blank'>
            contact us
          </Link>{' '}
          for our enterprise plans.
        </Text>
      </VStack>
    </Box>
  );
};
