import React, { FC } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';

interface FeatureCardProps {
  children: React.ReactNode;
  active: boolean;
  gradient: {
    direction: string;
    from: string;
    to: string;
  };
}

const FeatureCard: FC<FeatureCardProps> = ({ active = false, children, gradient }) => {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false
    },
    {
      fallback: 'md'
    }
  );

  return (
    <Box
      as={motion.div}
      w='full'
      h='full'
      borderRadius='2xl'
      bgGradient={`linear(${gradient.direction}, ${gradient.from}, ${gradient.to})`}
      position='absolute'
      inset={0}
      opacity={0}
      animate={active && !isMobile ? { opacity: [0, 1], scale: [0.9, 1] } : 'inherit'}
      whileInView={isMobile ? { opacity: [0, 1], scale: [0.9, 1] } : null}
    >
      {children}
    </Box>
  );
};

export default FeatureCard;
