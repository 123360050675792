import React from 'react';
import { Box, Heading, Image, Stack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import { getFloatAnimation } from '../../libs/utils';

export const ExploreFeatures = React.memo(() => {
  return (
    <>
      {/* Service management */}
      <Box as='section' overflow='hidden'>
        <Stack
          spacing={6}
          maxWidth='5xl'
          mx='auto'
          my={10}
          alignItems='center'
          mb={20}
          direction={{ base: 'column', md: 'row' }}
        >
          <Image
            src='/images/sections/02.webp'
            alt='Highly scalable'
            w='400px'
            h='auto'
            mr={10}
            animation={getFloatAnimation()}
          />
          <Box>
            <Heading size={{ base: 'sm', md: 'md' }}>Highly scalable</Heading>

            <Text color={mode('gray.600', 'gray.300')} mt='5' fontSize='xl' maxWidth='500px'>
              From a few rows to millions of lines, SmartParse can handle it all. Onboard as much data as your systems
              allow, or sync as much as your legacy applications can handle.
            </Text>
          </Box>
        </Stack>

        <Stack
          spacing={6}
          maxWidth='5xl'
          mx='auto'
          my={10}
          alignItems='center'
          direction={{ base: 'column-reverse', md: 'row' }}
        >
          <Box>
            <Heading size={{ base: 'sm', md: 'md' }}>Secure</Heading>

            <Text color={mode('gray.600', 'gray.300')} mt='5' fontSize='xl' maxWidth='500px'>
              Built on secure cloud technologies, SmartParse delivers best in class scalability and performance with
              99.98% uptime.
            </Text>
          </Box>

          <Image src='/images/sections/05.webp' alt='Secure' w='400px' h='auto' animation={getFloatAnimation()} />
        </Stack>

        <Stack
          spacing={6}
          maxWidth='5xl'
          mx='auto'
          my={10}
          alignItems='center'
          mb={20}
          direction={{ base: 'column', md: 'row' }}
        >
          <Image
            src='/images/sections/03.webp'
            alt='Customizable throughput'
            w='400px'
            h='auto'
            mr={10}
            animation={getFloatAnimation()}
          />
          <Box>
            <Heading size={{ base: 'sm', md: 'md' }}>Customizable throughput</Heading>

            <Text color={mode('gray.600', 'gray.300')} mt='5' fontSize='xl' maxWidth='500px'>
              Rate limit your migration to align with your destination APIs requirements. Set your own fault tolerances
              and retry criteria.
            </Text>
          </Box>
        </Stack>

        <Stack
          spacing={6}
          maxWidth='5xl'
          mx='auto'
          my={10}
          alignItems='center'
          direction={{ base: 'column-reverse', md: 'row' }}
        >
          <Box>
            <Heading size={{ base: 'sm', md: 'md' }}>Granular control</Heading>

            <Text color={mode('gray.600', 'gray.300')} mt='5' fontSize='xl' maxWidth='500px'>
              Job alerting and individual dashboards allow you to keep track of your workloads in real time. Handle
              errors at a line-by-line level so you can resolve them quickly and continue processing.
            </Text>
          </Box>

          <Image
            src='/images/sections/06.webp'
            alt='Granular control'
            w='400px'
            h='auto'
            animation={getFloatAnimation()}
          />
        </Stack>

        <Stack
          spacing={6}
          maxWidth='5xl'
          mx='auto'
          my={10}
          alignItems='center'
          mb={20}
          direction={{ base: 'column', md: 'row' }}
        >
          <Image
            src='/images/sections/01.webp'
            alt='>Cost-effective'
            w='400px'
            h='auto'
            mr={10}
            animation={getFloatAnimation()}
          />
          <Box>
            <Heading size={{ base: 'sm', md: 'md' }}>Cost-effective</Heading>

            <Text color={mode('gray.600', 'gray.300')} mt='5' fontSize='xl' maxWidth='500px'>
              Pricing based on utilisation with a generous free tier.
            </Text>
          </Box>
        </Stack>
      </Box>
    </>
  );
});

ExploreFeatures.displayName = 'ExploreFeatures';
