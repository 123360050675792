import { Box, BoxProps, Container, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

// import { FiX } from 'react-icons/fi';

interface BannerProps extends BoxProps {}

const Banner: FC<BannerProps> = (props) => {
  return (
    <Box borderBottomWidth='1px' bg='brand.500' {...props}>
      <Container py={4} justifyContent='center'>
        <HStack spacing={{ base: '3', md: '4' }} justify={{ base: 'start', md: 'space-between' }}>
          {/* <Box boxSize='8' display={{ base: 'none', md: 'block' }} /> */}
          <Text color='fg.emphasized' fontWeight='medium' mx='auto'>
            🎉 Introducing the new and improved mapping editor with a host of new utility functions.
          </Text>
          {/* <IconButton icon={<FiX />} variant='tertiary' aria-label='Close banner' /> */}
        </HStack>
      </Container>
    </Box>
  );
};

export default Banner;
