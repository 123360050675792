import React, { FC } from 'react';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  ListItem,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  UnorderedList,
  VStack
} from '@chakra-ui/react';

interface CaseStudiesProps {}

const caseStudies = [
  {
    title: 'Sales data consolidation',
    desc: 'The customer used SmartParse to consolidate sales data from various systems into a centralized data lake.',
    intro: 'Using SmartParse, quarterly reconciliation:',
    benefits: ['Improved error handling', 'concluded 31x faster', 'showed 50% cost savings']
  },
  {
    title: 'User import',
    desc: 'A customer switched to SmartParse for user imports for their intranets.',
    intro: 'Using SmartParse, the customer found the solution:',
    benefits: ['Almost fully automated', '120x quicker to build', '10x cheaper than outsourced alternatives']
  },
  {
    title: 'B2B bulk order processing',
    desc: 'A customer switched to SmartParse for reliable B2B processing at scale.',
    intro: 'Using SmartParse, the processing:',
    benefits: [
      'The total cost of ownership was 68x cheaper',
      'Cost saving north of 48%',
      'Improved uptime',
      'Reduced key man dependencies'
    ]
  },
  {
    title: 'Stock management',
    desc: 'A customer switched to SmartParse after a large stock import broke a production system.',
    intro: 'Using SmartParse, the new solution:',
    benefits: [
      'Had no downtime from brittle legacy systems',
      'Removed the developer bottleneck; Other business stakeholders could now manage the process.',
      'Was up and running within one week'
    ]
  },
  {
    title: 'Recon and settlement',
    desc: "A customer switched to SmartParse to manage their business transactions' three-way recons.",
    intro: 'Using SmartParse, the new solution:',
    benefits: [
      'Near zero developer overhead',
      'Was fully automated',
      'Saved SFTP server and related infrastructure costs',
      '43% reduction in maintenance costs'
    ]
  }
];

const CaseStudies: FC<CaseStudiesProps> = () => {
  const getCard = (index) => (
    <Card key={caseStudies[index].title} bg='bg-surface-landing' p={{ base: 4, md: 10 }} w='full' borderRadius='2xl'>
      <CardHeader>
        <Heading size='md'>{caseStudies[index].title}</Heading>
      </CardHeader>
      <CardBody>
        <Stack
          spacing={6}
          divider={<StackDivider borderColor='blackAlpha.300' _dark={{ borderColor: 'whiteAlpha.200' }} />}
        >
          <Text color='blackAlpha.800' _dark={{ color: 'whiteAlpha.800' }}>
            {caseStudies[index].desc}
          </Text>
          <Box>
            <Text mb={4}>{caseStudies[index].intro}</Text>
            <UnorderedList spacing={2}>
              {caseStudies[index].benefits.map((benefit) => (
                <ListItem
                  key={benefit}
                  sx={{
                    '&::marker': {
                      color: 'orange.500'
                    }
                  }}
                >
                  {benefit}
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} gap={6}>
      <VStack spacing={6} alignItems='start'>
        <Box px={12} py={{ base: 0, md: 12 }}>
          <Heading as='h2' fontWeight='bolder'>
            Case studies
          </Heading>
          <Heading
            as='h3'
            size='sm'
            fontSize='lg'
            color='blackAlpha.600'
            _dark={{ color: 'whiteAlpha.600' }}
            mb={{ base: 4, md: 12 }}
            ml={{ base: 0, md: 12 }}
          >
            Success stories from our customers
          </Heading>
        </Box>
        {getCard(0)}
        {getCard(2)}
      </VStack>
      <VStack spacing={6}>
        {getCard(4)}
        {getCard(1)}
        {getCard(3)}
      </VStack>
    </SimpleGrid>
  );
};

export default CaseStudies;
