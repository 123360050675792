/* eslint-disable max-len */
import React, { FC } from 'react';
import { Box, Heading, HStack, List, ListIcon, ListItem, Text, useColorModeValue } from '@chakra-ui/react';
import { BsFillQuestionSquareFill } from 'react-icons/bs';
import { FAQPageJsonLd } from 'next-seo';

interface FAQsProps {}

const FAQs: FC<FAQsProps> = React.memo(() => {
  const faqs = [
    {
      question: 'What is SmartParse, and how can it help my small business?',
      answer:
        'SmartParse is a tool that simplifies data migration from any flat file to any API with minimal setup, offering a quick, low-code solution for integrating your data systems.'
    },
    {
      question: 'Do I need extensive coding knowledge to use SmartParse?',
      answer:
        'SmartParse is designed to be low-code, making it accessible for users with minimal coding experience. The platform provides an intuitive mapping editor to simplify integrations.'
    },
    {
      question: 'Can SmartParse handle large volumes of data?',
      answer:
        'SmartParse is highly scalable and can manage data migrations from a few rows to millions of lines, accommodating the data throughput your systems can handle.'
    },
    {
      question: 'Is data transferred via SmartParse secure?',
      answer:
        'Absolutely. SmartParse is built on secure cloud technologies, SFTP as a service and industry-standard encryption, ensuring best-in-class scalability and performance with a focus on security.'
    },
    {
      question: 'What kind of files can I process with SmartParse?',
      answer:
        'SmartParse supports CSV files in all the variations, allowing you to process a wide range of flat file data with flavours including comma, semicolon delimiters, quote or non-quote wrapped columns and more.'
    },
    {
      question: 'How quick is the setup process for API destinations with SmartParse?',
      answer:
        'The setup for API destinations is designed to be completed in minutes (or seconds), providing a quick and efficient way to start your data migration process.'
    },
    {
      question: 'Does SmartParse offer SFTP integration?',
      answer:
        "Yes, SmartParse includes integrated SFTP as a service, so there's no need to set up your own SFTP server for secure file transfers."
    },
    {
      question: 'What kind of reporting does SmartParse offer?',
      answer:
        'SmartParse provides job digest emails with import summaries and intuitive reports on the dashboard, allowing you to spot successes and errors at a glance, along with an extract of errored, successful or unprocessed lines.'
    },
    {
      question: 'How does SmartParse ensure the reliability of data migration?',
      answer:
        'SmartParse offers customizable throughput, built-in fault detection, circuit breakers, and dynamic concurrency to prevent downstream flooding.'
    },
    {
      question: 'What are the pricing plans for SmartParse?',
      answer:
        'SmartParse offers a range of pricing plans based on utilization, with various options suitable for everything from infrequent small uploads to daily large-volume processing.'
    },
    {
      question: 'Is there a free trial available for SmartParse?',
      answer:
        "SmartParse's pricing structure includes a free trial for users to experience the service before committing to a paid plan."
    },
    {
      question: 'Can SmartParse be used for one-time data migrations?',
      answer: 'Yes, SmartParse is suitable for both infrequent, one-time uploads and regular, ongoing data migrations.'
    },
    {
      question: 'What makes SmartParse suitable for solopreneurs and small businesses?',
      answer:
        'SmartParse is cost-effective, easy to set up, and requires minimal coding, making it an ideal solution for solopreneurs and small businesses that need to migrate data efficiently without investing in complex infrastructure or extensive training.'
    }
  ];
  return (
    <Box id='faqs' mb={60}>
      <FAQPageJsonLd
        mainEntity={faqs.map((faq) => ({
          questionName: faq.question,
          acceptedAnswerText: faq.answer
        }))}
      />

      <Heading as='h2' size='3xl' mb={12} fontWeight='extrabold' textAlign='center'>
        Frequently Asked Questions
      </Heading>
      <Box>
        <List spacing={6} fontSize='lg' maxW='4xl' mx='auto'>
          {faqs.map((faq) => (
            <ListItem key={faq.question}>
              <HStack spacing={2} mb={0}>
                <ListIcon as={BsFillQuestionSquareFill} color={useColorModeValue('brand.600', 'brand.300')} />
                <Heading as='h3' size='xs' fontSize='xl' fontWeight='bold'>
                  {faq.question}
                </Heading>
              </HStack>
              <Text ml={9} fontSize='lg' color={useColorModeValue('gray.800', 'gray.300')}>
                {faq.answer}
              </Text>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
});

FAQs.displayName = 'FAQs';
export default FAQs;
